<!-- 快速查件 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('快速查件')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>
  <el-row style="padding: 20px 20px;">
      <el-col :xs="24" :sm="10">
          <el-input v-model="code" @keyup.native="autoSave" ref="codeRef" :placeholder="msg('请输入订单编号、查询码、面单码')" 
              size="medium">
          <el-button slot="append" icon="el-icon-search" @click="doCheck">查询</el-button>
          </el-input>
      </el-col>
  </el-row>

  <el-row v-if="stock">
      <el-form label-width="100px" label-position="right">
      <el-col :xs="18" :sm="6">
        <el-form-item label="订单编号：">
          {{stock.code}}
        </el-form-item>
      </el-col>
      <el-col :xs="18" :sm="6">
        <el-form-item label="查询码：">
          {{stock.expCode}}
        </el-form-item>
      </el-col>
      <el-col :xs="18" :sm="6">
        <el-form-item label="面单码：">
          {{stock.expPageCode}}
        </el-form-item>
      </el-col>
    </el-form>
  </el-row>
  <el-row v-if="stock">
      <el-form label-width="100px" label-position="right">
        <el-col :xs="18" :sm="6">
          <el-form-item label="袋号：">
            {{stock.expPkgCode}}
          </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6">
          <el-form-item label="架位号：">
            {{stock.shelfCode}}
          </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6">
          <el-form-item label="归属人：">
            {{stock.createrName}}
          </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6">
          <el-form-item label="归属公司：">
            {{stock.company}}
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  <el-row v-if="stock">
    <el-form label-width="100px" label-position="right">
        <el-col :xs="18" :sm="6">
          <el-form-item label="订单来源：">
            {{stock.orderOrigin}}
          </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6">
          <el-form-item label="配送方式：">
            {{stock.expCompany}}
          </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6">
          <el-form-item label="货态：">
            {{stock.status}}
          </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6">
          <el-form-item label="仓储类型：">
            {{stock.storeType}}
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :xs="24" :sm="12">
       <div v-if="data" class="ctrl-panel">
        <h3>{{fmt('单号：%s'+"物流跟踪详情", [data.code])}}</h3>
        <div class="trace-wrap">
            <div class="trace-box">
                <h4>{{msg('物流状态跟踪')}}</h4>
                <el-timeline :reverse="true">
                    <el-timeline-item
                        type="danger"
                        v-for="(item, index) in data.statuses" :key="index"
                        :timestamp="item.created">
                            {{item.createrName}} : {{item.ctrl}} - {{item.label}}-{{item.remark}}
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div>
                <h4>{{msg("台湾物流状态跟踪")}}</h4>
                <el-timeline :reverse="true">
                    <el-timeline-item
                        type="danger"
                        v-for="(item, index) in data.twExpTracks" :key="index"
                        :timestamp="item.created">
                            {{item.remark}}
                    </el-timeline-item>
                </el-timeline>
            </div>

        </div>
        <h4>{{msg('国内物流详情')}}</h4>
        <div class="details">
            <div class="detail">
                <span>{{data.code}}</span>
                <a target="_blank" :href="'https://www.baidu.com/s?wd='+data.code">{{msg("点击查看物流")}}</a>
            </div>
            <span v-for="(item, idx) in data.details" :key="idx">
                <div class="detail" v-if="item.innerExpCode">
                    <span>{{item.innerExpCode}}({{item.shelfCode}})</span>
                    <a target="_blank" :href="'https://www.baidu.com/s?wd='+item.innerExpCode">{{msg("点击查看物流")}}</a>
                </div>
            </span>
        </div>
      </div>
    </el-col>
  </el-row>

</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'Document',
  components: {},
  mounted:function(){
  },
  data(){
    return{
        visiable: false,
        videoUrl: null,
        videos: [], 
        docs: [],  
        data: null,
        code:null,
        stock:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 250;
        }
    }
  },
  methods: {
      autoSave(e){
          if(e.keyCode == 13){
              this.doCheck();
          }
      },
      doCheck(){
        this.data = null;
        this.stock = null;
        this.$axios.post(this.$kit.api.stock.tracesNew+"?code="+this.code+"&hist=")
            .then((result) => {
                if(result.status){
                    let data = result.data;
                    this.data = data.dto;
                    this.stock = data.stock;
                    if(this.stock){
                        this.stock.orderOrigin = this.dicts.orderOrigin.find(d => d.value === this.stock.orderOrigin).label;
                        this.stock.status = this.dicts.stockStatus.find(d => d.value === this.stock.status).label;
                        this.stock.storeType = this.dicts.stockStoreType.find(d => d.value === this.stock.storeType).label;
                    }
                    this.code = null;
                }else{
                    this.$message(result.msg || this.msg('获取失败'));
                }
            });
      }
  }
}
</script>

<style scoped lang="scss">
.trace-wrap{display:flex; justify-content: space-between;}
.trace-box{padding: 10px;}
.detail{margin:10px;}
.detail a{display:inline-block; margin-left: 8px;}
</style>
