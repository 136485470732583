<!-- 统计报表-->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template>
                    <el-row>
                        <el-form-item :label="msg('搜索类型')" label-width="80px">
                            <el-select size="mini" v-model="query.selectType" placeholder="请选择" clearable>
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <el-form-item>
                                <el-input size="mini" v-model="query.name" clearable></el-input>
                            </el-form-item>
                        </el-form-item>
                        <el-form-item :label="msg('仓库')">
                            <el-select v-model="query.storeId"  placeholder="请选择" size="mini">
                                <el-option
                                    v-for="item in stores"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-form-item :label="msg('日期类型')" label-width="80px">
                            <el-select size="mini" v-model="query.dateType" placeholder="请选择" clearable>
                                <el-option
                                v-for="item in dateOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <el-date-picker  v-model="query.Date" type="datetimerange"  :range-separator="msg('至')" 
                                value-format="yyyy-MM-dd HH:mm:ss"
                                end-placeholder=""
                                size="mini"
                                clearable
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                size="mini"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="stockDetails"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'StoreDetail',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        this.buildColumns();
        // this.list();
        this.getStores();
    },
    data(){
        return {
            query:{
                length: 100,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                storeId:null,
                name:null,
                Date:[],
                selectType:null,
                dateType:null,
            },
            showQuery: true,
            curr: null,
            columns: [],
            stockDetails: [],
            stores: [],
            options:[
                {
                    value: 1,
                    label: '用户名称'
                },
                {
                    value: 2,
                    label: '用户账号'
                },
                {
                    value: 3,
                    label: '归属公司'
                },
            ],
            dateOptions:[
                {
                    value: 1,
                    label: '创建时间'
                },
                {
                    value: 2,
                    label: '揽收时间'
                },
                {
                    value: 4,
                    label: '出货时间'
                },
                {
                    value: 3,
                    label: '列印面单'
                },
            ],
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'seq', minWidth: 40},
                {field: 'fullname', title: this.msg('用户名称'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'username', title: this.msg('用户账号'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'company', title: this.msg('归属公司'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'storeName', title: this.msg('仓库'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'roleLabel', title: this.msg('角色'), minWidth: 120, sortable: true,
                   filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'userTag', title: this.msg('用户组别'), minWidth: 120, sortable: true,
                   filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'total', title: this.msg('出货数量'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'weightExp', title: this.msg('总重量(KG)'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'printTime', title: this.msg('最后打印面单日期'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'weightTime', title: this.msg('最后录重日期'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'created', title: this.msg('最后创建日期'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'pkgTime', title: this.msg('最后装袋日期'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
            ]
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores.splice(0,this.stores.length);
                        this.stores = result.data;
                    }
                });
        },
        list(){

            if(this.query.name){
                if(!this.query.selectType){
                    this.$message(this.msg('请选择搜索类型'));
                    return;
                }
            }
            if(this.query.Date && this.query.Date.length > 0){
                if(!this.query.dateType){
                    this.$message(this.msg('请选择日期类型'));
                    return;
                }
            }

            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.stock.reportList, param)
                .then((result) => {
                    if(result.status){
                        this.stockDetails = result.data.list;
                        this.query.pageTotal = result.data.total;
                        if(this.stockDetails && this.stockDetails.length > 0){
                            this.stockDetails.forEach(e => {
                                if(e.weightExp){
                                    e.weightExp = (e.weightExp/1000).toFixed(3);
                                }
                            })
                        }
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
