<!-- 快递管理 -->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="addExp()" v-if="hasPermission('expOrder:add')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('新增')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="editExp(1)" v-if="hasPermission('expOrder:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('变更仓库')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="editExp(2)" v-if="hasPermission('expOrder:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiugai" font-size="27px;"/>
                            <span class="txt">{{msg('变更状态')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="editExp(3)" v-if="hasPermission('expOrder:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-xiangqingxiugai" font-size="27px;"/>
                            <span class="txt">{{msg('变更姓名')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="editExp(4)" v-if="hasPermission('expOrder:edit')" >
                        <div class="wrap" >
                            <icon-svg icon-class="icon-fabutongzhi1" font-size="27px;"/>
                            <span class="txt">{{msg('变更备注')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('expOrder:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('快递单号')">
                        <el-input type="textarea"  :rows="3" v-model="scope.query.innerExpCodes" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('快递单号')">
                        <el-input v-model.trim="scope.query.innerExpCode" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('签收状态')">
                        <el-select v-model="scope.query.received" placeholder="请选择" size="mini" clearable 
                            @clear="clearReceived"
                        >
                            <el-option
                            v-for="item in receiveds"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('订单状态')">
                        <el-select v-model="scope.query.status" placeholder="请选择" size="mini" clearable 
                            @clear="clearStatus"
                        >
                            <el-option
                            v-for="item in dicts.stockStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('到货仓库')">
                        <el-select v-model="query.storeId"  placeholder="请选择" size="mini" clearable 
                            @clear="clearStore"
                        >
                            <el-option
                                v-for="item in stores"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('归属人')">
                        <el-input v-model.trim="scope.query.ownerName" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('归属公司')">
                        <el-input v-model.trim="scope.query.company" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注')">
                        <el-input v-model.trim="scope.query.remark" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('录单时间')" label-width="80px">
                        <el-date-picker  v-model="query.innerDate" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('签收时间')" label-width="80px">
                        <el-date-picker  v-model="query.receivedDate" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                size="mini"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="stockDetails"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

        <el-dialog :title="msg('新增快递')" :visible.sync="addExpVisible" :fullscreen="isMinScreen"  
            :close-on-click-modal="false" 
            @opened="openedAddExp"
                width="600px">
            <express-order-add ref="addExpRef" v-on:close-dg="closeExpDg"/>
        </el-dialog>

        <el-dialog :title="editTitle" :visible.sync="editExpVisible" :fullscreen="isMinScreen"  
            :close-on-click-modal="false" 
            @opened="openedEditExp"
                width="600px">
            <express-order-edit ref="editExpRef" v-on:close-dg="closeeditExpDg"/>
        </el-dialog>

    </div>  
</template>
<script>

import Pagenator from "../../components/Pagination.vue";
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import ExpressOrderAdd from "./ExpressOrderAdd.vue";
import ExpressOrderEdit from "./ExpressOrderEdit.vue";

export default {
    name: 'ExpressOrder',
    components:{
        Pagenator:Pagenator,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        ExpressOrderAdd:ExpressOrderAdd,
        ExpressOrderEdit:ExpressOrderEdit
    },
    mounted:function(){
        this.buildColumns();
        this.getStores();
        this.list();
    },
    data(){
        return {
            query:{
                length: 100,
                start: 0,
                pageIndex:1,
                pageTotal: 0,
                innerExpCodes:null,
                innerExpCode:null,
                received:null,
                storeId:null,
                ownerName:null,
                company:null,
                innerDate:[],
                receivedDate:[],
                remark:null,
                status:null,
            },
            showQuery: false,
            curr: null,
            columns: [],
            stockDetails: [],
            stores: [],
            receiveds:[
                {value: 0, label: "配送中"},
                {value: 1, label: "已签收"},
            ],
            addExpVisible:false,
            editTitle:null,
            editType:null,
            editExpVisible:false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        buildColumns(){
            this.columns = [
                {type: 'checkbox', minWidth: 40},
                {field: 'id', title: this.msg('ID'), minWidth: 80, sortable: true},
                {field: 'innerDate', title: this.msg('录单时间'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'days', title: this.msg('已过天数'), minWidth: 100, sortable: true,
                    filters:[{data: {type: 'has', isCase: false, name: ''}}],
                    filterRender:{name: 'FilterComplex'}
                },
                {field: 'storeName', title: this.msg('到货仓库'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: 'innerExpCode', title: this.msg('快递单号'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},
                    slots: {
                        default: ({ row }) => {
                            return [
                                <el-link type="primary" href={'https://www.baidu.com/s?wd='+row.innerExpCode} target="_blank">{row.innerExpCode}</el-link>
                            ];
                        },
                    }
                },
                {field: 'received', title: this.msg('签收状态'), minWidth: 100, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            return [
                                <el-tag type={row.received==1?'success':'danger'}>{row.received==1?'已签收':'配送中'}</el-tag>
                            ];
                        },
                    }
                },
                {field: 'receivedDate', title: this.msg('签收时间'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
                {field: 'shelfCode', title: this.msg('架位号'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'ownerName', title: this.msg('归属人'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'company', title: this.msg('归属公司'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'orderCode', title: this.msg('对应订单'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'status', title: this.msg('订单状态'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.stockStatus.find(d => d.value === row['status']);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row['status']}</el-tag>
                            ];
                        },
                    },
                },
                {field: 'remark', title: this.msg('备注'), minWidth: 150, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
            ]
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores = result.data;
                    }
                });
        },
        addExp(){
            this.addExpVisible = true;
        },
        openedAddExp(){
            this.$nextTick(() => {
                this.$refs.addExpRef.init();
            });
        },
        closeExpDg(){
            this.addExpVisible = false;
            this.list();
        },
        clearReceived(){
            this.query.received = null;
        },
        clearStatus(){
            this.query.status = null;
        },
        clearStore(){
            this.query.storeId = null;
        },
        editExp(type){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(type == 1){
                this.editTitle = '变更仓库';
            }else if(type == 2){
                this.editTitle = '变更状态';
            }else if(type == 3){
                this.editTitle = '变更姓名';
            }else if(type == 4){
                this.editTitle = '变更备注';
            }
            this.editType = type;
            this.editExpVisible = true;
        },
        openedEditExp(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            this.$nextTick(() => {
                this.$refs.editExpRef.init(selectes.map(s=>s.id).join(','),this.editType);
            });
        },
        closeeditExpDg(){
            this.editExpVisible = false;
            this.list();
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let ids = selectes.map(s=>s.id).join(',');
            this.$confirm(this.msg('确定要删除该快递吗?'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                this.$axios.post(this.$kit.api.stock.expOrderDelete, ids)
                .then((result) => {
                    this.$message(result.msg);
                    this.list();
                });
            }).catch((err) => {
            });
        },
        list(){
            if(this.query.innerExpCodes){
                let cs = this.query.innerExpCodes.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["innerExpCodes"] = css.join(",");
            }else{
                this.query["innerExpCodes"] = '';
            }
            var param = this.$kit.rebuildQuery(this.query);

            this.$axios.post(this.$kit.api.stock.expressOrderList, param)
                .then((result) => {
                    if(result.status){
                        this.stockDetails = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                    }
                });
        },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
