<!-- 条码校对 -->
<template>
<div class="content-panel">
    <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
        <el-col :span="20">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{msg('条码校对')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
    </el-row>
    <el-row :gutter="10" type="flex" justify="center" class="query-wrap" >
        <el-col :xs="24" :sm="8">
            <el-input v-model.trim="code1" ref="ref1" :placeholder="msg('请扫描条码')" @keyup.native="autoCode1"></el-input>
        </el-col>
        <el-col :xs="24" :sm="8">
            <el-input v-model.trim="code2" ref="ref2" :placeholder="msg('请扫描条码')" @keyup.native="autoCode2"></el-input>
        </el-col>
    </el-row>

    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="small"  @click="doSubmint"><i class="el-icon-check"></i><span>{{msg('提交')}}</span></el-button>
    </div>
    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio controls="controls" preload ref="audioRef" >
        </audio>
    </div>

</div> 


</template>

<script>
import right from "../../assets/media/right.mp3";//正确
import error from "../../assets/media/error.mp3";//错误
export default {
  name: 'ExpressSorting',
  data(){
    return{
        code1:null,
        code2:null,
        audioUrl:null,
    }
  }, 
  props: {
    },
  computed:{
  },
  mounted: function(){
      this.$refs['ref1'].focus();
  },
  methods:{
    init(){
        this.code = null;
    },
    autoCode1(e){

        if(e.keyCode == 13){
            this.$refs['ref2'].focus();
        }

    },
    autoCode2(e){

        if(e.keyCode == 13){
            this.doSubmint();
        }

    },
    doSubmint(){
        if(!this.code1 || !this.code2){
            this.$message(this.msg('单号不能为空'));
            this.clearCode();
            this.$refs['ref1'].focus();
            return;
        }
        console.log(this.code1);
        console.log(this.code2);
        // if(this.code1 === this.code2){
        //     this.playAudio(error);
        //     this.clearCode();
        //     this.$refs['ref1'].focus();
        //     return;
        // }
        let param = {
            code:this.code1,
            expPageCode:this.code2
        }
        this.$axios.post(this.$kit.api.stock.stockCheckCode,param)
            .then((result) =>{
                if(result.status){
                    this.playAudio(right);
                    this.clearCode();
                    this.$refs['ref1'].focus();
                }else{
                    this.playAudio(error);
                    this.clearCode();
                    this.$refs['ref1'].focus();
                }
               
            });
    },
    playAudio(url){
        let audio = this.$refs.audioRef;
        audio.src = url;
        audio.play();
    },
    clearCode(){
        this.code1 = null;
        this.code2 = null;
    },
  }
}
</script>

<style scoped lang="scss">
.content-panel{
    min-height: 800px;
}
.query-wrap{
    padding: 50px 50px;
}
</style>
