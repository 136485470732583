<!-- 出货管理 -->
<template>
  <div>
        <ctrl-box class="ctrl-box">
            <template>
                <div class="xgroup">
                    <el-link :underline="false"  v-popover:colsPopover >
                        <i class="el-icon-s-grid" ></i>
                    </el-link>
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('袋号')">
                        <el-input type="textarea"  :rows="3" v-model="scope.query.expPkgCodes" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('货物状态')">
                        <el-select v-model="scope.query.states" multiple placeholder="请选择">
                            <el-option
                            v-for="item in hqStatus"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('仓库')">
                        <el-select v-model="query.storeId" multiple  placeholder="请选择" size="mini">
                            <el-option
                                v-for="item in stores"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('物流公司')">
                        <el-select v-model="query.expCompanies" multiple  placeholder="请选择" size="mini"
                            clearable >
                            <el-option
                                v-for="item in expCompanys"
                                    :key="item.label"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('批次')">
                        <el-input v-model="scope.query.expBatch" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('车次信息')">
                        <el-input v-model="scope.query.trainNumber" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('班次信息')">
                        <el-input v-model="scope.query.expFlight" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('清关信息')">
                        <el-input v-model="scope.query.expQingGuan" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('提货信息')">
                        <el-input v-model="scope.query.expTiHuo" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('流程备注')">
                        <el-input v-model="scope.query.remark3" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注说明1')">
                        <el-input v-model="scope.query.remark7" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('备注说明2')">
                        <el-input v-model="scope.query.remark8" ></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('出货时间')" label-width="80px">
                        <el-date-picker  v-model="query.Date" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="msg('录袋重时间')" label-width="90px">
                        <el-date-picker  v-model="query.weightDate" type="datetimerange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <!-- <el-button type="primary" icon="el-icon-search" @click="list(1)" size="mini">{{msg('搜索统计旧')}}</el-button> -->
                        <el-button type="primary" icon="el-icon-search" @click="list(2)" size="mini">{{msg('搜索统计')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full" size="mini"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :scroll-y="{gt: 80}"
                :data="hqLists"
                :columns="columns"
            ></vxe-grid>
        </div>

        <el-popover
                ref="colsPopover"
                width="200"
                placement="bottom"
                v-model="colsPopVisible"
                trigger="click">
            <el-scrollbar style="height:300px;">
                <div v-for="(c, idx) in genColumns" :key="idx">
                    <el-checkbox v-if="idx>0" v-model="c.visible"
                            @change="(val)=>refreshCols(val, c)"
                        >{{c.title}}</el-checkbox>
                </div>
            </el-scrollbar>
        </el-popover>

    </div>  
</template>
<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";

export default {
    name: 'simpleProductName',
    components:{
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
    },
    mounted:function(){
        let date = new Date();
        let dateL = date.getTime();
        let endDate = dateL - (30*24*3600*1000);
        
        this.query.Date = [
            this.$kit.fmtDateTime(new Date(endDate)),
            this.$kit.fmtDateTime(date),
        ];

        this.buildCustom();
        this.getStores();
        this.getCompanys();
    },
    data(){
        return {
            query:{
                expBatch: null,
                expQingGuan: null,
                expTiHuo:null,
                remark3: null,
                remark7: null,
                remark8: null,
                Date: [], 
                weightDate:[],
                expPkgCodes:null,
                trainNumber:null,
                expFlight:null,
                states:[55,60,65],
                storeId:[],
                expCompanies: [],
            },
            stores: [],
            hqStatus:[
                {value: 50, label: "已发货", type: "success"},
                {value: 55, label: "转运中", type: "primary"},
                {value: 60, label: "待验中", type: "primary"},
                {value: 65, label: "已清关", type: "primary"},
                {value: 70, label: "已提货", type: "success"},
                {value: 75, label: "转配送", type: "success"},
            ],
            colsPopVisible: false, //字段选择弹窗
            genColumns:[],
            showQuery: true,
            curr: null,
            expCompanys:[],
            hqLists: [],
            columns: [
                {type: 'checkbox', minWidth: 60},
                {type : "seq", minWidth: 60, sortable: true},
                {field: 'formatDate', title: this.msg('出货时间'), minWidth: 100, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expPkgCode', title: this.msg('袋号'), minWidth: 100, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'total', title: this.msg('票数'), minWidth: 60, sortable: true},
                {field: 'expBatch', title: this.msg('批次'), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expCompany', title: this.msg('配送方式'), minWidth: 120, sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'}
                },
                {field: 'status', title: this.msg('货物状态'), minWidth: 80, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'trainNumber', title: this.msg('车次信息'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expFlight', title: this.msg('班次信息'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expZhuanYun', title: this.msg('转运信息'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expDaiYan', title: this.msg('待验信息'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expQingGuan', title: this.msg('清关信息'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'expTiHuo', title: this.msg('提货信息'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'hasPage', title: this.msg('已有面单'), minWidth: 60, sortable: true},
                {field: 'printPage', title: this.msg('已打印面单'), minWidth: 60, sortable: true},
                {field: 'notPrintPage', title: this.msg('未打印面单'), minWidth: 60, sortable: true},
                {field: 'notPull', title: this.msg('未获取面单'), minWidth: 60, sortable: true},
                {field: 'notUpload', title: this.msg('未上传面单'), minWidth: 60, sortable: true},
                {field: 'uploadTotal', title: this.msg('上网票数'), minWidth: 60, sortable: true},
                {field: 'remark8', title: this.msg('备注说明2'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'remark7', title: this.msg('备注说明1'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'remark3', title: this.msg('流程备注'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'productName', title: this.msg('品名'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'store', title: this.msg('仓库'), minWidth: 60, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: 'weight1', title: this.msg('累加重'), minWidth: 80, sortable: true},
                {field: 'weight2', title: this.msg('托运重'), minWidth: 80, sortable: true},
                {field: 'pkgWeight', title: this.msg('整袋重'), minWidth: 80, sortable: true},
                {field: 'pkgWeightTime', title: this.msg('录袋重时间'), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
            ],
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
            return this.$store.state.isMinScreen;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
    },
    methods: {
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores.splice(0,this.stores.length);
                        this.stores = result.data;
                    }
                });
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        buildCustom(){
            setTimeout(() => {
                this.$nextTick(() => {
                    this.genColumns = this.$refs.dataTable.getColumns()
                })
            }, 1000);
        },
        list(type){
            if(!this.query.expBatch && !this.query.expQingGuan && !this.query.expTiHuo 
                && !this.query.remark3 && !this.query.remark7 && !this.query.remark8 
                && (!this.query.Date || this.query.Date.length<=0) && !this.query.expPkgCodes 
                && !this.query.expFlight && (!this.query.weightDate || this.query.weightDate.length<=0)
                && this.query.states.length<=0 && this.query.storeId.length<=0 ){
                    this.$message(this.msg('请输入条件'));
                    return;
            }

            // if(!this.query.Date || this.query.Date.length<2){
            //     this.$message(this.msg('请选择出货时间'));
            //     return;
            // }

            if(this.query.expPkgCodes){
                let cs = this.query.expPkgCodes.split(/\s+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                this.query["expPkgCode"] = css.join(",");
            }else{
                this.query["expPkgCode"] = '';
            }
            let statusSelec = [];
            if(this.query.states.length > 0){
                statusSelec = this.query.states;
                this.query["states"] = this.query.states.join(",");
            }

            let storeSelec = [];
            if(this.query.storeId.length > 0){
                storeSelec = this.query.storeId;
                this.query["storeId"] = this.query.storeId.join(",");
            }

            var companies = this.query.expCompanies;
            var param = this.$kit.rebuildQuery(this.query);
            param.expCompanies = companies;

            this.$axios.post(this.$kit.api.export.hqList+"?type="+type, param)
                .then((result) => {
                    if(result.status){
                        this.hqLists = result.data;
                        this.query.states = statusSelec;
                        this.query.storeId = storeSelec;
                    }else{
                        this.$message(result.msg || this.msg('获取失败'));
                        this.query.states = statusSelec;
                        this.query.storeId = storeSelec;
                    }
                });
        },
        reset(){
                this.query.expBatch = null;
                this.query.expQingGuan= null;
                this.query.expTiHuo=null;
                this.query.remark3=null;
                this.query.remark7=null;
                this.query.remark8=null;
                this.query.Date=[];
                this.query.weightDate=[];
                this.query.expPkgCodes=null;
                this.query.expFlight=null;
                this.query.expCompanies= [];
                this.query.states=[];
                this.query.storeId=[];
        },
        doExport(){
            // this.$refs.dataTable.exportData({ filename:"航空清关信息",type: 'csv' })
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        refreshCols(visible, col){
            this.$refs.dataTable.refreshColumn()
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
