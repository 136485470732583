<!-- 快速打单 -->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('货运管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('快速打单')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <!-- <el-form label-width="100px" οnsubmit="return func()">
            <input type="hidden" name="hide" />
            <el-row style="margin-top:20px; margin-left: 2px;margin-right: 2px;">
                <el-col :xs="24" :sm="10">
                    <el-form-item :label="msg('单号')">
                        <el-input v-model="code" ref="codeRef" placeholder="请扫描单号" 
                            size="medium" 
                            @keyup.native="autoSelectCode">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item>
                        <el-button type="primary" size="mini"  @click="doPrint"><i class="el-icon-check"></i><span>{{msg('打印')}}</span></el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>  -->
        <el-alert
            :title="msg('快速打印提示')"
            type="error">
        </el-alert>
        <el-row style="padding: 20px 20px;">
            <el-col :xs="24" :sm="10">
                <el-input v-model="code" ref="codeRef" :placeholder="msg('请扫描订单编号、面单码、袋号')" 
                    size="medium" 
                    @keyup.native="autoSelectCode">
                </el-input>
            </el-col>
            <el-col :xs="24" :sm="8">
                <template>
                    <!-- <el-button type="primary" size="medium"  @click="doPrint"><i class="el-icon-check"></i><span>{{msg('打印当前')}}</span></el-button> -->
                    <el-checkbox style="margin-left:10px" v-model="pastType">代贴单登记</el-checkbox>
                </template>
            </el-col>
        </el-row>

        <div class="page-tpl">
            <vue-easy-print tableShow ref="easyPrint">
                <template>
                    <print-tpl :datas="printDtos" ></print-tpl>
                </template>
            </vue-easy-print>
        </div>

        <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
            <audio controls="controls" preload ref="audioRef"></audio>
        </div>

    </div>
</template>

<script>
import Cancel from "../../assets/media/Cancel.mp3"; //订单已取消
import Warning from "../../assets/media/warning.wav";//滴滴音
import unexistPageMp3 from "../../assets/media/page_unexist.mp3";  //面单不存在
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintFastTpl";
export default {
  name: 'StockPrintFast',
  components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
  data(){
    return{
        code:null,
        printDtos:[],
        datas:[],
        codes:[],
        pastType:true,
        audioUrl:null,
    }
    
  }, 
  computed:{
  },
  mounted(){
    this.code = null;
    this.printDtos = [];
    this.$refs['codeRef'].focus();
  },
  watch:{
      $route(){
        //跳转到该页面后需要进行的操作
        this.code = null;
        this.printDtos = [];
        this.$refs['codeRef'].focus();
      }
    },
  methods:{
    func(){
        return false;
    },
    autoSelectCode(e){
        if(e.keyCode == 13){
            // this.codes.splice(0,0,this.code);
            this.selectStock();
        }

    },
    selectStock(){
        if(!this.hasPermission('stock:printFastMiandan')){
            this.$message(this.msg('抱歉，会员权限不够，请升级会员!'));
            return;
        }
        // let temp = this.codes.pop();
        this.$axios.get(this.$kit.api.stock.getFastPrintData+"?code="+this.code+"&pastType="+this.pastType,)
            .then((result) =>{
                if(result.status){
                    this.printDtos.splice(0,this.printDtos.length);
                    let data = result.data;
                    if(data && data.length > 0){
                        this.printDtos = data;
                        let dto = data[0];
                        if(dto.code.indexOf("面单不存在") > -1){
                            //声音提示不存在
                            this.audioUrl = unexistPageMp3;
                            this.playAudio();
                        }else if(dto.code.indexOf("订单已取消") > -1){
                            //声音提示已取消
                            this.audioUrl = Cancel;
                            this.playAudio();
                        }else{
                            this.doPrint();
                        }

                    }else{
                        this.printDtos=[];
                        this.audioUrl = Warning;
                        this.playAudio();
                        this.$message(this.msg("单号不存在"));
                        return;
                    }
                }else{
                    this.$message({
                        message: result.msg,
                        type: 'error'
                    });
                }
            });

        this.code = null;
    },
    doPrint(){
        // this.printDtos = this.datas.pop();
        setTimeout(()=>{
            this.$refs.easyPrint.print();
        }, 200);
    },
    playAudio(){
        let audio = this.$refs.audioRef;
        audio.src = this.audioUrl;
        audio.play();
    },
  }
}
</script>

<style scoped lang="scss">

</style>
