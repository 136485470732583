<!-- 扫描二维码 -->
<template>
    <div class="content-panel">
        <div style="margin-top:10px; margin-bottom:10px; text-align:center;">
            <el-button type="danger" icon="icon-saomiao" @click="openCamera">{{btnTxt}}</el-button>
        </div>
        <video style="display:none;" ref="video"></video>
        <div style="text-align:center; overflow:auto; margin:10px;">
            <div v-show="openedCamera" style="position:relative;display:inline-block;">
                <canvas id="canvas" ref="canvas" style="background: white;"></canvas>
                <canvas id="canvas1" style="position:absolute; top:0px; left:0px; " ref="canvas1"></canvas>
            </div>
        </div>
        <div style="margin-top:10px; margin-bottom:10px; text-align:center;">
            <span>{{msg("架位号")}}:</span>
            <el-input v-model="shelfCode" :placeholder="msg(请输入架位号)"
                style="width:200px; margin-left:10px;"
            ></el-input>
        </div>
        <div style="margin-top:10px; margin-bottom:10px; text-align:center;">
            <el-button type="primary" @click="prepareDecode"><span class="iconfont icon-saomiao"></span>{{btnTxt1}}</el-button>
        </div>

        <div>
            <h3>{{msg("扫描上架结果")}}</h3>
            <vxe-grid ref="dataTable" height="350px" 
                export-config stripe
                resizable border="full"
                highlight-current-row highlight-hover-column highlight-current-column
                show-header-overflow show-overflow
                :data="results"
                size="mini"
                :columns="columns"
                :scroll-x="{gt: 20}"
                :scroll-y="{gt: 20}"
            ></vxe-grid>
        </div>

        <!--
            <div ref="input1"></div>
            <p>视频信息：{{info}}</p>
            <p>遮罩信息：{{cinfo}}</p>
            <p>{{codes}}</p>
         -->
        <!--
        <img :src="imgSrc" />
        -->
        <canvas id="canvas2" style="display:none;" ref="canvas2"></canvas>

        <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
            <audio controls="controls" preload ref="audioRef" :src="scanOk" >
            </audio>
        </div>

    </div>
</template>

<script>
import QrcodeDecoder from 'qrcode-decoder';
import scanOk from "../../assets/media/scanOk.mp3"; 
export default {
    name: 'StockQRUpp',
    components:{
        
    },
    data(){
        return{
            codes:[], //扫描到的二维码数据号码
            shelfCode: null, //架位号
            cacheCodes: [],
            minScreen: null,
            docWidth: null,
            docHeight: null,
            mheight: null,
            mwidth: null,
            // visibleVideo: false,
            openedCamera: false,
            btnTxt: this.msg('开启摄像头'),
            btnTxt1: this.msg("开始扫描"),
            scaning: false, //开启扫描
            openSave: false,    //开始自动保存
            stream: null,   //视频流
            timer: null,    //视频流绘图到canvastimer
            decodeTimer: null,  //截图图片，并解析的timer
            openSaveTimer: null,
            imgSrc: null,   
            inited: false,
            isDrawBorder: false,    //是否绘画过矩形框
            scanOk: scanOk,
            rect: {
                x: 0,
                y: 0,
                width:160,
                height:160
            }, 
            info: null,
            cinfo: null,
            results: [],
            columns:[]
        }
    },
    computed:{
    },
    mounted(){
        this.clearData();
        this.buildColumns();
    },
    watch:{
        $route(){
            //跳转到该页面后需要进行的操作
            this.codes = [];
        }
    },
    methods:{
        buildColumns(){
            //status: 0-处理中，1-成功，2-失败
            this.columns=[
                {field: 'index', title: "#", sortable: true, maxWidth: 75, width: 75, minWidth: 75,
                    slots: {
                        default: ({ row }) => {
                            return row.index+1;
                        },
                    }
                },
                {field: 'code', title: this.msg('单号')},
                {field: 'msg', title: this.msg('消息'), maxWidth: 110, width: 110, minWidth: 110, sortable: true},
            ];
        },
        isIOS(){
            var u = navigator.userAgent.toLowerCase();

            return  u.indexOf('iphone') > -1  //是否为iPhone或者QQHD浏览器
                    || u.indexOf('ipad') > -1 //是否iPad
                    || false; //是否web应该程序，没有头部与底部
        },
        isPhone(){
            var u = navigator.userAgent.toLowerCase();

            return  u.indexOf('iphone') > -1  //是否为iPhone或者QQHD浏览器
                    || u.indexOf('ipad') > -1 //是否iPad
                    || u.indexOf('android') > -1
                    || false; //是否web应该程序，没有头部与底部
        },
        clearData(){
            try{
                this.btnTxt = '开启摄像头';
                this.openedCamera = false;
                this.$refs.video.srcObject = null
                this.$refs.video.pause();
                this.cancalCloseVideo();
                this.timer && clearInterval(this.timer);
                this.codes = [];
                this.cacheCodes = [];
            }catch(err){
                console.error(err);
            }
        },

        initData(){
            this.minScreen = this.$store.isMinScreen;
            this.docWidth = (this.$store.docWidth || window.docWidth) - 40;
            this.docHeight = (this.$store.docHeight || window.docHeight)-40;
            
            this.mwidth = this.isPhone()?this.docWidth-50:600;
            this.mheight =  this.isPhone()?this.docHeight:500;
        },
        //开启摄像头
        openCamera(){
            this.initData();
            if(this.openedCamera == true){
                this.clearData();
                return;
            }
            let option = null;
            if(this.isPhone()){
                option = {
                    // width: this.mwidth,
                    // height:this.mheight,
                    video: true,
                    facingMode: {exact: "environment"} //后置 ,
                }
            }else{
                option = {
                    // width: this.mwidth,
                    // height:this.mheight,
                    video: true,
                }
            }
            
            let _this = this;
            this.inited = true;
            if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
                    navigator.mediaDevices.getUserMedia({
                    video: option
                }).then((stream) => {
                    //将视频流实时播放在video
                    let video = _this.$refs.video;
                    video.srcObject = stream;
                    video.play();

                    _this.stream=typeof stream.stop==='function'?stream:stream.getTracks()[0];

                    //截取video内容
                    _this.timer = setInterval(() => {
                        _this.screenShot()
                    }, 80);

                    _this.btnTxt = '关闭摄像头';
                    _this.openedCamera = true;

                    setTimeout(()=>{
                        _this.drawBorder();
                    }, 200);

                }).catch((err) => {
                    _this.$message(err+"");
                });

            }else if(navigator.getUserMedia){
                navigator.getUserMedia({
                    video: true
                }).then((stream) => {

                    //将视频流实时播放在video
                    let video = _this.$refs.video;
                    video.srcObject = stream;
                    video.play();
                    
                    _this.stream=typeof stream.stop==='function'?stream:stream.getTracks()[0];

                    _this.timer = setInterval(() => {
                        _this.screenShot()
                    }, 80);

                    _this.btnTxt = '关闭摄像头';
                    _this.openedCamera = true;

                    setTimeout(()=>{
                        _this.drawBorder();
                    }, 1000);

                }).catch((err) => {
                    _this.$message(err)+"";
                });
            }else{
                // let ipt = document.createElement("input");
                // ipt.name = "pic";
                // ipt.type = "file";
                // ipt.accept="image/*"
                // ipt.change(function(e){
                //     var file = ipt.files[0];
                //     if (file) {
                //         var reader = new FileReader(); //实例化
                //         reader.readAsDataURL(file); //加载
                //         reader.onload = function () {
                //             var dataURL = this.result;
                //             _this.decodeQRCode(dataURL);
                //             this.$refs.input1.empty();
                //         }
                //     }
                // });
                // this.$refs.input1.append(ipt);
                this.$message("您的浏览不支持该功能");
            }
        },
        //将视频流绘制到canvas上面
        screenShot(){
            let video = this.$refs.video;
            let vwidth = video.videoWidth;
            let vheight = video.videoHeight;

            let scale = 1;
            let isPhone = this.isPhone();
            if(isPhone){
                if(vwidth>this.docWidth){
                    scale = this.docWidth / vwidth;
                }
            }
            
            let w1 = vwidth*scale;
            let h1 = vheight*scale;

            let canvas = this.$refs.canvas;
            canvas.width = w1;
            canvas.height = h1;

            let context = canvas.getContext("2d");

            //这里将原图放大两倍，在取中间的
            

            this.info = JSON.stringify({
                srcWidth: vwidth,
                srcHeight: vheight,
                scale: scale,
                scaleWidth: w1,
                scaleHeight: h1,
            });

            if(isPhone){
                context.drawImage(video, (w1/2), h1/2, w1, h1, 0, 0, vwidth, vheight); 
            }else{
                context.drawImage(video, 0, 0); 
            }
            //放大两倍去只
            // context.drawImage(video, (w1/3), h1/3, w1/1.5, h1/1.5, 0, 0, vwidth, vheight); 
            // let data = canvas.toDataURL('image/png');
            // this.imgSrc=data;
            // this.context.drawImage(data, 0,0);
            // this.decodeQRCode(data);
        },
        //绘制一个遮罩，
        drawBorder(){

            if(this.drawImage)
                return;

            let video = this.$refs.video;
            let vwidth = video.videoWidth;
            let vheight = video.videoHeight;

            let scale = 1;
            if(this.isPhone()){
                if(vwidth>this.docWidth){
                    scale = this.docWidth / vwidth;
                    vwidth = this.docWidth;
                    vheight = vheight * scale;
                }
            }

            let canvas = this.$refs.canvas1;
            canvas.width = vwidth;
            canvas.height = vheight;
            let context = canvas.getContext("2d");
            
            context.fillStyle = 'rgba(110,110,110,0.5)';
            context.fillRect(0, 0, vwidth, vheight);

            let recWidth = 200;
            let recHeight = 200;
            let x1 = (vwidth - recWidth)/2;
            let y1 = (vheight - recHeight)/2;
            
            context.clearRect(x1, y1, recWidth, recHeight);

            this.rect.x = x1;
            this.rect.y = y1;
            this.rect.width = recWidth;
            this.rect.height = recHeight;

            this.cinfo = JSON.stringify({
                srcWidth: video.videoWidth,
                srcHeight: video.videoHeight,
                scale: scale,
                scaleWidth: vwidth,
                scaleHeight: vheight,
                recy: this.rect
            });

            this.isDrawBorder = true;
        },
        //开启、关闭扫描
        prepareDecode(){
            if(this.scaning){
                clearInterval(this.decodeTimer);
                this.decodeTimer = null;
                this.scaning = false;
                this.btnTxt1 = this.msg('开始扫描');
            }else{

                if(!this.shelfCode){
                    this.$message("请输入架位号");
                    return;
                }

                if(this.openSave == false){
                    //开启自动保存
                    this.openSaveTimer = setInterval(()=>{
                        this.autoSave();
                    }, 1000);
                    this.openSave = true;
                }

                this.decodeTimer = setInterval(()=> {
                    this.doDecodeQR();
                }, 100);
                this.btnTxt1 = this.msg('停止扫描');
                this.scaning = true;
            }
        },

        //解析图片二维码
        doDecodeQR(){
            let canvas = this.$refs.canvas;
            let data = canvas.toDataURL('image/png');  

            let img = new Image();
            img.src = data;

            let canvas1 = this.$refs.canvas2;
            canvas1.width = this.rect.width;
            canvas1.height = this.rect.height;
            let context = canvas1.getContext("2d");
            img.onload = ()=>{
                let w = this.rect.width; 
                let h = this.rect.height;
                context.drawImage(img, this.rect.x, this.rect.y, w, h, 0, 0,w,h);

                // let data1 = canvas1.toDataURL('image/png');  
                try{
                    // this.code = qrcode().decode(canvas1);
                    var qr = new QrcodeDecoder();
                    qr.decodeFromImage(img).then((res) => {
                        if(res){
                            let data = res.data;
                            
                            if(this.cacheCodes.indexOf(data) == -1){
                                this.playOk();
                                this.cacheCodes.push(data);
                                this.codes.splice(0,0,data);
                            }

                            // this.codes.push();
                            // clearInterval(this.decodeTimer);
                            // this.decodeTimer = null;
                        }
                    });
                }catch(err){
                    console.error(err);
                }
            }

        },
        playOk(){
            this.$refs.audioRef.play();
            if(window.navigator && window.navigator['vibrate']){
                window.navigator.vibrate([200, 100, 200]);
            }
        },
        cancalCloseVideo(){
            this.stream && this.stream.stop();
        },
        autoSave(){

            if(this.codes.length>0){
                let code = this.codes.pop();
                
                 this.$axios.get(this.$kit.api.stock.qrupp+"?code="+encodeURIComponent(code)+"&shelfCode="+this.shelfCode)
                    .then((result) =>{
                        if(result.status){
                            let data = result.data;
                            let idx = this.results.length || 1;
                            data["index"] = idx;
                            this.results.push(data);
                        }
                    })
            }

        },
    },
    beforeDestroy(){
        this.cancalCloseVideo();
        
        if(this.openSaveTimer){
            clearInterval(this.openSaveTimer);
            this.openSaveTimer = null;
        }

        if(this.decodeTimer){
            clearInterval(this.decodeTimer);
            this.decodeTimer = null;
        }

        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }

    },
}
</script>

<style scoped lang="scss">

</style>
