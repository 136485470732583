import { render, staticRenderFns } from "./StockQRUpp.vue?vue&type=template&id=b36b157a&scoped=true"
import script from "./StockQRUpp.vue?vue&type=script&lang=js"
export * from "./StockQRUpp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b36b157a",
  null
  
)

export default component.exports