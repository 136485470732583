<!-- 新增快递 -->
<template>
<el-form label-width="100px">
    <el-row>
        <el-col :xs="24" :sm="20">
            <el-form-item :label="msg('快递单号')">
                <el-input v-model.trim="innerExpCode" placeholder="请输入快递单号" size="small"></el-input>
            </el-form-item>
            <el-form-item :label="msg('仓库')">
                <el-select v-model="storeId"  placeholder="请选择仓库" size="small" clearable 
                    @clear="clearStore"
                >
                    <el-option
                        v-for="item in stores"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="msg('归属人')">
                <el-autocomplete size="small" clearable
                    v-model="ownerName"
                    :fetch-suggestions="searchUser"
                    :highlight-first-item="true"
                    :placeholder="this.msg('搜索归属人')"
                    :trigger-on-focus="false"
                    @select="selectUser"
                    @clear="clearUsername"
                    ref="userRef"
                ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="msg('备注')">
                <el-input v-model.trim="remark" placeholder="请输入备注" size="small"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
        stores:[],
        innerExpCode:null,
        storeId:null,
        owner:null,
        remark:null,
        ownerName:null,
    }
    
  }, 
  computed:{
  },
  mounted: function(){
  },
  methods:{
    init(){
        this.innerExpCode = null;
        this.storeId = null;
        this.owner = null;
        this.ownerName = null;
        this.remark = null;
        this.getStores();
    },
    getStores(){
        this.$axios.post(this.$kit.api.store.listUsed)
            .then((result)=> {
                if(result.status){
                    this.stores.splice(0,this.stores.length);
                    this.stores = result.data;
                }
            });
    },
    clearStore(){
        this.storeId = null;
    },
    searchUser(queryString, cbFn){  //搜索用户
        this.$axios.get(this.$kit.api.user.search+"?name="+encodeURIComponent(queryString)+"&type=1")
            .then((result) => {
                if(result.status){
                    let users =[];
                    result.data.forEach(e => {
                        users.push({
                            id: e.id,
                            value: e.value
                        });
                    });
                    cbFn(users);
                }
            });
    },
    selectUser(item) { //获取选中的用户
        this.owner = item.id;
        this.ownerName = item.value;
    },
    clearUsername(){
        this.owner = null;
        this.ownerName = null;
    },
    onSubmit(){
        var _this = this;

        if(!this.innerExpCode){
            this.$message(this.msg('请输入快递单号'));
            return;
        }
        if(!this.storeId){
            this.$message(this.msg('请选择仓库'));
            return;
        }

        let param = {
            innerExpCode : this.innerExpCode,
            storeId : this.storeId,
            owner : this.owner,
            remark : this.remark
        }
        this.$axios.post(this.$kit.api.stock.expOrderAdd,param)
            .then((result) =>{
                _this.$message(result.msg);
                if(result.status){
                    this.innerExpCode = null;
                }
            });
    },
  }
}
</script>

<style scoped lang="scss">


</style>
