<!--快速打单模板-->
<template>
  <div
    class="print-box"
    :style="{display: pageLength?'block':'inline-block'}"
  >
    <div class="print-wrap" v-for="(data, index) in datas" :key="index">
        <div class="exp-page" style="position:relative;">
            <img :style="getStyle(data)" 
              :src="data.pageAsBase64"
            />
            <div><span style="position:absolute;top:0px;left:22px;font-size:20px;font-weight:bolder;">{{data.storeTag}}</span></div>
            <!--
            <span class="position:absolute; buttom:10px; right:10px;">F</span>
            -->
        </div>
        <div class="next-page a5" v-if="pageLength"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StockPrintFastTpl",
  props: {
    datas: Array,
  },
  computed: {
        pageLength: { 
            get() {
                if(this.datas.length > 1){
                  return true;
                }else{
                  return false;
                }
            }
        },
  },
  methods:{
    getStyle(dto){
      let style = {width: "400px"}
      if(dto.expCompany==='7-11'){
        if(dto.orderOrigin == 1){
          style={width: '390px'}
        }else{
          style = {width: "360px"};
        }
      }else if(dto.expCompany === '全家'){
        if(dto.orderOrigin == 1){
            style={width: '390px'}
        }
        if(dto.platform != 1){
            style={height: '595px'}
        }
      }
      // if(dto.platform==1){
      //   if(dto.expCompany!= '7-11'){
      //     style={width:"400px"}
      //   }
      // }else if(dto.expCompany == '7-11'){
      //   style = {width: "350px"}
      // }else if(dto.expCompany=='全家'){
      //   if(dto.orderOrigin == 1){
      //     style={width: "400px"}
      //   }else if(dto.platform != 1){
      //     style={height: "612px"}
      //   }
      // }else if(dto.expCompany == '萊爾富'){
      //   if(dto.errStatus === 4){
      //     style={width: "400px",}
      //   }else{
      //     style={maxHeight: "612px",}
      //   }
      // }
      return style;
    },
  }
};
</script>

<style lang="scss" scoped>



.print-box {
  width: 100%;
  margin-top: 10px;
}
.print-wrap {
  margin: 0px auto;
  width: 400px;
}
.print-wrap h3 {
  margin: 6px 0px;
  text-align: center;
  font-size: 16px;
}
.next-page {
  page-break-before: always;
}
.exp-page {
  text-align: center;
  max-height:612px; 
  overflow: hidden;
}

.codeSpan-711{
  position: relative;
  left: -100px;
  top: -25px;
  z-index:1;
  font-size: 20px;
  font-weight:bold
}
</style>
